<!-- 互动 -->
<template>
    <div>
        <div class="message-board-default">
            <div class="message-board-default-left">
            <img
            class="headImage"
            :src="
                subUserInfo?.headImage
                ? subUserInfo.headImage
                : require('./../../../assets/images/userL.png')
            "
            alt=""
            />
            </div>
            <div class="message-board-default-right-box">
            <div class="message-board-default-right">
                <textarea placeholder="说说你的想法..." resize="none" :readonly="issend" maxlength="1000" v-model="comment"></textarea>
                <div class="send-box">
                <button @click="send(0)" type="button" :disabled="issend">发送</button>
                </div>
            </div>
            </div>
        </div>
        <ul>
            <li v-for="(item) in list" :key="item.id">
                <div class="message-board-default">
                    <div class="message-board-default-left">
                        <img
                            class="headImage"
                            :src="item.userImge || require('./../../../assets/images/userL.png')"
                            alt=""
                        />
                    </div>
                    <div class="message-board-default-right-box">
                        <p class="info-user">
                            <span class="name">{{item.userName}}</span>
                            <span class="time">{{ $dayjs(item.createTime).format('YYYY.MM.DD HH:mm') }}</span>
                        </p>
                        <p class="comment-content">{{ item.comment }}</p>
                        <div class="handle-box handle-end">
                            <div class="handle-box-left">
                            <span :class="item.marker?'active':''">
                                <i @click="saveLikes(item)"><svg-icon icon-class="dianzan_huaban"></svg-icon></i>
                                {{ item.likes }}
                            </span>
                            <span>
                                <i @click="remark(item)"><svg-icon icon-class="pinglun"></svg-icon></i>
                                {{ item.numComments }}
                            </span>
                            </div>
                        </div>
                        <div class="message-board-default-right" v-if="item.showInput">
                            <textarea placeholder="说说你的想法..." :readonly="issend" maxlength="1000" v-model="comment1"  resize="none"></textarea>
                            <div class="send-box">
                            <button @click="send(1,item)" type="button" :disabled="issend">发送</button>
                            </div>
                        </div>
                        <ul class="send-cls" v-if="item.childList && item.childList.length">
                            <li v-for="(el) in item.childList" :key="el.id">
                                <div class="message-board-default">
                                    <div class="message-board-default-left">
                                        <img
                                            class="headImage"
                                            :src="item.userImge || require('./../../../assets/images/userL.png')"
                                            alt=""
                                        />
                                    </div>
                                    <div class="message-board-default-right-box">
                                        <div class="handle-box">
                                            <div class="handle-box-left info-user">
                                            <span class="name">{{ el.userName }}</span>
                                            <span class="time">{{ $dayjs(el.createTime).format('YYYY.MM.DD HH:mm') }}</span>
                                            </div>
                                            <div class="handle-box-right">
                                            <span @click="saveLikes(el)" :class="el.marker?'active':''">
                                                <i>{{ el.likes }}</i>
                                                <svg-icon icon-class="dianzan_huaban"></svg-icon>
                                            </span>
                                            </div>
                                        </div>
                                        <p class="comment-content">{{ el.comment }}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
        <p class="loadingmore load-more" @click="reachBottom" v-if="paging.total!=this.list.length">加载更多</p>
        <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
    </div>
</template>
<script>
export default{
    data(){
        return{
            noData: null,
            issend:false,
            comment:'',
            comment1:'',
            list:[],
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 10,
                },
                total: 0,
            },
        }
    },
    computed: {
        subUserInfo() {
            return this.$store.state.subUserInfo;
        },
        noMore() {
            return (
                this.list.length === this.paging.total && this.paging.total !== 0
            );
        },
    },
    created(){
        this.getInteract();
    },
    methods:{
        reachBottom() {
            if (
                this.paging.total > this.list.length
            ) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.getInteract();
            } 
        },
        remark(item){
            this.$set(item,'showInput',true)
        },
        getInteract(){
            const params = {
                ...this.paging.params
            }
            const data = {
                itemType:2,
                itemId:this.$route.query.id
            }
            this.$api.board.findUserList({params,data}).then(res=>{
                if(res.code==0){
                    if (res.data.list && res.data.list.length) {
                        this.noData = false;
                    } else {
                        this.noData = true;
                    }
                    if (this.paging.params.pageNum > 1) {
                        this.list = this.list.concat(res.data.list);
                    } else {
                        this.list = res.data.list || [];
                    }
                    console.log(res);
                    this.paging.total = res.data.total;
                }
            })
        },
        // 点赞取消
        saveLikes(item){
            const params ={
                id:item.id
            }
            this.$api.board.saveLikes({
                params
            }).then(res=>{
                if(res.code==0){
                    if(item.marker){
                        this.$set(item,'likes',item.likes-1)
                        this.$set(item,'marker',0)
                    }else{
                        this.$set(item,'likes',item.likes+1);
                        this.$set(item,'marker',1)
                    }
                }
                console.log(res);
            })
        },
        send(type,item){
            if(!this.comment && type==0){
                this.$message.warning('请填写评论内容');
                return 
            }
            if(!this.comment1 && type==1){
                this.$message.warning('请填写评论内容');
                return 
            }
            this.issend = true;
            let data
            if(!type){
                data = {
                    comment:this.comment,
                    itemId:this.$route.query.id,
                    itemType:2//1:计划 2:任务
                }
            }else{
                data ={
                    comment:this.comment1,
                    itemId:this.$route.query.id,
                    parentId:item.id,
                    itemType:2//1:计划 2:任务
                }
            }
            this.$api.board.interactSave(data).then(res=>{
                if(res.code==0){
                    this.$message.success('发送成功');
                    this.getInteract();
                }
                if(type){
                    this.comment1='';
                    this.$set(item,'showInput',false);
                }else{
                    this.comment = '';
                }
                this.issend = false
            }).catch(()=>{
                this.issend = false;
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.load-more{
    color: #1A72FF;
    cursor: pointer;
}
ul{
    li{
      margin-top: 24px;
      .message-board-default-right{
        margin-bottom: 16px;
        position: relative;
        &::after{
          position: absolute;
          left: 0;
          right:0;
          bottom: -16px;
          content:'';
          background: #EEF1F7;
          height: 1px;
        }
      }
    }
}
.send-cls{
    padding: 0 0 0 0;
    li{
        border-bottom: 1px solid #EEF1F7;
        &:nth-last-child(1){
            border-bottom: none;
        }
        &:nth-child(1){
            margin-top: 40px;
        }
    }
    .message-board-default{
        .message-board-default-left{
            img{
                width: 32px;
                height: 32px;
            }
        }
    }
    .handle-box{
        .handle-box-left{
            span{
                width: auto;
                &:hover{
                    cursor: auto;
                    opacity: 1;
                }
            }
        }
        .handle-box-right{
            span{
                &:hover{
                    opacity: .7;
                    cursor: pointer;
                }
                .icon{
                    margin-right: 0px;
                    margin-left: 6px;
                }
            }
        }
    }
}
.message-board-default{
  display: flex;
  .message-board-default-left{
    img{
      width: 48px;
      height: 48px;
      object-fit: contain;
      border-radius: 50%;
      margin-right: 12px;
    }
  }
  .message-board-default-right-box{
    width:calc(100% - 50px);
    .time{
      color: #999;
      margin-left: 12px;
    }
    .comment-content{
      margin-bottom: 16px;
      margin-top: 12px;
    }
  }
  .message-board-default-right{
    padding: 12px 16px;
    border-radius: 4px;
    background: #F8F9FB;
    height: 192px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    textarea{
      width: 100%;
      background: transparent;
      border: none;
      appearance: none;
      outline: none;
      height: 126px;
      font-size: 14px;
      line-height: 24px;
      overflow: auto;
      resize: none;
    }
  }
  .send-box{
    position: absolute;
    left: 0;
    right: 24px;
    bottom: 24px;
    text-align: right;
    button{
      width: 98px;
      height: 42px;
      border-radius: 6px;
      background: $WDPrimaryColor;
      outline: none;
      border: none;
      color: #fff;
      appearance: none;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
    }
  }
}
.info-user{
    line-height: 14px;
    .name{
        font-weight: 600;
    }
}
.handle-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  line-height: 14px;
  font-size: 14px;
  .active{
        color: #1A72FF!important;
    }
  .remark-btn{
    color: #1A72FF;
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
  }
  i{
    height: 14px;
    line-height: 14px;
    font-style: normal;
  }
  .handle-box-left{
    color: #666;
    span{
      display: inline-block;
      width: 94px;
      cursor: pointer;
      &:hover{
        opacity: .7;
      }
      .icon{
        margin-right: 4px;
      }
    }
  }
}
</style>